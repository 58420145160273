/**
 * Custom hook to add AJAX search functionality to a component. When added to component, AJAX search will run on mount
 * and every time query string changes
 *
 * Returns object with three keys: hasServerErrored (boolean), isFetching (boolean) and results (array of results);
 */

// @flow
import { useState, useEffect } from 'react';
import { toRelativeLink, toCamelCase } from '../utils/index';

const url = process.env.GATSBY_SEARCH_INDEXER_ENDPOINT;
const searchIndex = process.env.GATSBY_SEARCH_INDEXER_INDEX;

const useFetchSearchResults = (queryString: string) => {
  const [results, setResults] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [hasServerErrored, setHasServerErrored] = useState(false);

  useEffect(() => {
    if (!queryString) {
      setResults([]);
      setIsFetching(false);
      return;
    }
    setIsFetching(true);
    const searchURL = `${url}/${searchIndex}/_search?size=50&q=${queryString.toLowerCase()}`;
    async function fetchData() {
      try {
        const resp = await fetch(searchURL, {
          method: 'GET'
        });
        const json = await resp.json();

        // eslint-disable-next-line flowtype/no-weak-types
        const parsedResults = json.hits.hits.map((itemContainer: Object) => {
          const parsedItem = {
            id: itemContainer._id
          };
          const item = itemContainer._source;
          Object.keys(item).forEach((itemKey: string) => {
            const value =
              itemKey === 'url' ? toRelativeLink(item[itemKey]) : item[itemKey];
            parsedItem[toCamelCase(itemKey)] = value;
          });
          return parsedItem;
        });
        setResults(parsedResults);
        setHasServerErrored(false);
      } catch (err) {
        setHasServerErrored(true);
      }
      setIsFetching(false);
    }
    fetchData();
  }, [queryString]);

  return {
    results,
    isFetching,
    hasServerErrored
  };
};

export default useFetchSearchResults;
