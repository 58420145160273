// @flow
import React from 'react';
import { Link } from '@latitude/link';

type Result = {
  /** Unique ID of search result */
  id: string,

  /** Title of Search Result. This will be clickable to the user */
  metaTitle: string,

  /** Sentence(s) describing search result  */
  metaDescription: string,

  /** URL to link to  */
  url: string
};

type Props = {
  /** Optional title displayed before all search results */
  title?: string,

  /** Array of Search results, each containing 4 keys (id, metaTitle, metaDescription, url) */
  results: Array<Result>
};

const SearchResultList = (props: Props) => {
  const { title, results } = props;
  return (
    <React.Fragment>
      {title && results.length > 0 && <h4 className="mt-0 mb-3">{title}</h4>}
      <ul className="list-unstyled pl-0 mt-0 mb-0 pt-1 pb-2">
        {results.map((result: Result) => (
          <li key={result.id} className="mb-4">
            <Link
              className="text--large d-inline pb-1"
              trackId="search-result-link"
              href={`/${result.url}`}
            >
              {result.metaTitle}
            </Link>
            <p className="my-0 pb-1 mt-1">{result.metaDescription}</p>
          </li>
        ))}
      </ul>
    </React.Fragment>
  );
};

export default SearchResultList;
