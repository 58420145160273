import React from 'react';
import queryString from 'query-string';
import Helmet from 'react-helmet';
import { SecondaryHero } from '@latitude/hero';
import { MARGIN, PADDING, COLOR } from '@latitude/core/utils/constants';
import Layout from '../components/layout';
import SearchResultList from '../components/SearchResultList/SearchResultList';
import useFetchSearchResults from '../hooks/useFetchSearchResults';
import { Loading } from '../components/Loading/Loading';
import { Box } from '../components/Box/Box';

const SearchPage = ({ location }) => {
  let subTitle;
  const queryParams = queryString.parse(location.search);
  const searchQuery = queryParams.q ? queryParams.q : '';
  const { results, isFetching, hasServerErrored } = useFetchSearchResults(
    searchQuery
  );

  if (hasServerErrored) {
    subTitle =
      'The server encountered a temporary error and could not complete the search. Please try again in 30 seconds.';
  } else if (!searchQuery) {
    subTitle = 'No search term was entered';
  } else if (!isFetching) {
    if (results.length) {
      subTitle = `${results.length} results found for ‘${searchQuery}’`;
    } else {
      subTitle = `Sorry, we were unable to locate any search results for ‘${searchQuery}’`;
    }
  }

  const mostRelevantResults = results.slice(0, 3);
  const otherResults = results.slice(3, results.length);

  return (
    <Layout location={location}>
      <main className="navigation-spacer">
        <Helmet>
          <title>
            Personal Loans, Debt Consolidation &amp; Credit Cards | Gem by
            Latitude
          </title>
          <link rel="canonical" href={`${location.href}`} />
          <meta name="robots" content="noindex, nofollow" />
          <meta name="description" content="" />
        </Helmet>

        <SecondaryHero
          heading="Results"
          subHeading={subTitle || <React.Fragment>&nbsp;</React.Fragment>}
        />

        <Box
          isResponsive
          isBodyCopy
          margin={`0 ${MARGIN.MAUTO}`}
          padding={`${PADDING.P32} 0 0`}
        >
          {isFetching ? (
            <Box paddingTop={MARGIN.M32} paddingBottom={MARGIN.M32}>
              <Loading />
            </Box>
          ) : (
            <SearchResultList
              title="Most Relevant"
              results={mostRelevantResults}
            />
          )}
        </Box>

        {otherResults.length > 0 && (
          <Box backgroundColor={COLOR.GREY5}>
            <Box
              isResponsive
              isBodyCopy
              margin={`0 ${MARGIN.MAUTO}`}
              padding={`${PADDING.P32} 0 0`}
            >
              <SearchResultList title="More results" results={otherResults} />
            </Box>
          </Box>
        )}
      </main>
    </Layout>
  );
};

export default SearchPage;
