import Accounting from 'accounting';

export const monthsToYearMonthString = totalMonths => {
  const months = totalMonths % 12;
  const yearsDecimal = totalMonths / 12;
  const years = months > 0 ? yearsDecimal.toFixed(1) : yearsDecimal;

  let timeString = '';
  if (years >= 1) {
    timeString += `${years} year`;
    if (years > 1) {
      timeString += 's';
    }
  } else if (months > 0) {
    timeString += `${months} months`;
  }
  return timeString;
};

export const formatAsCurrency = num => {
  if (isNaN(num) || num === Infinity || !num) {
    return '';
  }
  return Accounting.formatMoney(num, '$', 0);
};

/**
 * roundToPrecision
 *
 * @param {number} num - float number to be rounded
 * @param {number} precision - float precision to round to
 * @returns {number}
 */
export const roundToPrecision = (num = 0, precision = 0) => {
  // eslint-disable-next-line no-restricted-properties
  const multiplicator = Math.pow(10, precision);
  const tempNum = parseFloat((num * multiplicator).toFixed(11));
  return Math.round(tempNum) / multiplicator;
};

// TODO: remove when old loan calculators (car loan, low rate) are updated
export const getAmortisationSchedule = (
  loanAmount,
  loanAnnualRate,
  paymentAmount
) => {
  const monthlyRate = loanAnnualRate / (100 * 12);
  let remaining = loanAmount;
  const schedule = [];

  let i = 0;
  while (remaining > 0) {
    const interest = remaining * monthlyRate;
    remaining += interest - paymentAmount;
    schedule.push({ remaining, interest });
    // safeguard against infinite loops, limit at 7 years
    if (i >= 84) {
      break;
    }
    i++;
  }
  return schedule;
};

// TODO: remove when old loan calculators (car loan, low rate) are updated
export const getMonthlyRepayment = (
  loanAmount,
  loanAnnualRate,
  loanDurationYears
) => {
  const numPayments = loanDurationYears * 12;
  const monthlyRate = loanAnnualRate / (100 * 12);
  // eslint-disable-next-line no-restricted-properties
  const x = Math.pow(1 + monthlyRate, numPayments);
  return (loanAmount * x * monthlyRate) / (x - 1);
};

// converts absolute URL string to relative URL, useful for staging enviroment
export const toRelativeLink = linkString =>
  // eslint-disable-next-line no-useless-escape
  linkString.replace(/^(?:\/\/|[^\/]+)*\//, '');

// converts snake_case or kebab-case string to camelCase
export const toCamelCase = string =>
  // eslint-disable-next-line flowtype/require-parameter-type
  string.replace(/([-_][a-z])/gi, $1 =>
    $1
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  );
